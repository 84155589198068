import React from 'react'
import { Box, Container, Icon, Link as A, Text } from '@hackclub/design-system'
import Link from 'components/Link'
import Layout from 'components/Layout'
import Nav from 'components/Nav'
import Carousel from 'nuka-carousel'
import { Flip } from 'react-reveal'
import Footer from 'components/Footer'
import { graphql } from 'gatsby'
import Social from '../components/Socialicons'
import {
  Headline,
  FeatureLink,
  Slider,
  PhotoFeature,
  HourFeatures
} from 'components/Content'
import codeImg from '../assets/imgs/code.jpg'
import bg from '../assets/imgs/bg.jpg'

A.link = A.withComponent(Link)

const title = 'Blogs at- Coding Sastra'

export default ({ data }) => {
  let posts = data.allContentfulPost.nodes
  console.log()
  return (
    <Layout
      title={title}
      desc={posts[0].body.body}
      keywords={posts[0].body.body}
      path="/blog/"
    >
      <Nav color="slate" fixed />
      <Social />
      <Box mt={[44, 55]} p={3} bg="snow">
        <Carousel
          autoplay
          autoplayInterval={5000}
          wrapAround
          enableKeyboardControls
          renderCenterLeftControls={({ previousSlide }) => (
            <Icon
              glyph="view-back"
              color="smoke"
              size={48}
              pb={3}
              onClick={previousSlide}
              style={{ cursor: 'pointer' }}
            />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <Icon
              glyph="view-forward"
              color="smoke"
              size={48}
              pb={3}
              pr={3}
              onClick={nextSlide}
              style={{ cursor: 'pointer' }}
            />
          )}
        >
          {posts.map(post => {
            return (
              <div key={post.id}>
                <Slider
                  style={{ paddingTop: 50 }}
                  src={post.picture ? post.picture.file.url : bg}
                  py={[3, 5, 6]}
                  color="white"
                  align={['left', 'center']}
                >
                  <Container
                    width={1}
                    maxWidth={70}
                    px={3}
                    mt={[5, 6]}
                    mb={[4, 5]}
                  >
                    <Flip top>
                      <Headline
                        maxWidth={58}
                        mx="auto"
                        fontSize={[6, 7, 8]}
                        mb={1}
                      >
                        {post ? post.title : ''}
                      </Headline>
                    </Flip>
                  </Container>
                </Slider>
              </div>
            )
          })}
        </Carousel>
      </Box>
      <Box bg="white" py={[5, 6]}>
        <Container color="black" px={3}>
          <HourFeatures>
            {posts.map(post => {
              return (
                <section key={post.id}>
                  <FeatureLink to={`/post/${post.id}/`} color="white">
                    <PhotoFeature
                      src={post.picture ? post.picture.file.url : codeImg}
                    >
                      <Text>{post.title}</Text>
                    </PhotoFeature>
                  </FeatureLink>
                </section>
              )
            })}
          </HourFeatures>
        </Container>
      </Box>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query PostPageQuery {
    allContentfulPost {
      nodes {
        id
        title
        body {
          body
        }
        picture {
          file {
            url
          }
        }
        author {
          name
        }
      }
    }
  }
`
